@use '/node_modules/@rocketcentral/rocket-design-system-styles/theme-react';
@use '/node_modules/@rocketcentral/rocket-design-system-styles/rocket.scss';
@use '/node_modules/@rocketcentral/rocket-design-system-styles/web/scss/color';

.rkt-Link {
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: #de3341;
  }

  &:active {
    text-decoration: none;
    color: #de3341;
  }
}

.rkt-Link--on-dark {
  &:not(.rkt-Link--is-disabled) {
    &:hover {
      color: #de3341;
    }
    &:active {
      text-decoration: none;
      color: #de3341;
    }
  }
}

.no-max-width {
  max-width: none;
}
